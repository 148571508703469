.App {
  text-align: left;
  
}

.Body {

  min-height: calc(94vh) ;

  margin: 0;
  background-color:  #000000;
  color: #ffffff;
}


.pageContent {
  text-align: center;
  font-size: calc(12px + 1vmin);
}


.pageContentLong {
  text-align: center;
  font-size: calc(12px + 1vmin);
  min-height: calc(1000px);
}


.pageContentWrapped {
  text-align: center;
  font-size: calc(10px + 1vmin);
  margin-left: calc(30vw - 75px);
  margin-right: calc(30vw - 75px);
  margin-top: 0%;
}


.pageContentSmall {
  text-align: center;
  font-size: calc(6px + 1vmin);
}

.pageContentSmall2 {
  text-align: left;
  font-size: calc(6px + 1vmin);
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 0%;
}

.pageContentMed {
  text-align: center;
  font-size: calc(16px + 1vmin);
}


.pageContentHead3 {
  text-align: center;
  font-size: calc(32px );
  font-weight: 500;
}

.pageContentMed2 {
  text-align: center;
  font-size: calc(18px + 1vmin);
  font-weight: 500;
}

.App-logo {
  height: 56vmin;
  pointer-events: none;
}

.emailLink {
  
  top :0;
  margin: 10px;
  font-size: calc(8px + 1vmin);
  color: white;
}


.App-header {
  background-color: #ffffff;
  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.navBarLink {
  position : sticky;
  top :0;
  margin: 10px;
  font-size: calc(14px + 1vmin);
  color: white;
}

.navBarLinkActive {
  position : sticky;
  top :0;
  margin: 10px;
  font-size: calc(14px + 1vmin);
  color: rgb(252, 239, 66);
 
}


.navBarTitle {
  margin: 5vmin;
  font-size: calc(26px + 2vmin);
  position : sticky;
  top :0;
  color: white;
  
}

.navBarBox {
  margin: 0px;
  font-size: calc(26px + 2vmin);
  position : sticky;
  top :0;
  color: rgb(0, 0, 0);
  background-color:  #30375f;
}

.footBox {
  margin: 0px;
  font-size: calc(4px + 1vmin);
  text-align: center;
  bottom : 0;
  color: rgb(172, 172, 172);
  background-color:  #161616;
  max-height: 6vh ;
  min-height: 6vh ;
  bottom: 0;
}


.divBox {
  margin: 0px;
  font-size: calc(1px);
  text-align: center;
  bottom : 0;
  color: rgb(0, 0, 0);
  background-color:  #2e2e2e; 
  
}

